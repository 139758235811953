import React from 'react'
import StatusPageContainer from "./components/StatusPageContainer";
import {QUIZ_BASE_URL} from "../shared/utils/quiz-api-services";
import {PAGE_STATUS} from "../shared/utils/constants";
import {createRoot} from "react-dom/client";

const domContainer = document.querySelector('#status');
const statusRoot = createRoot(domContainer)
const url = new URL(location.href);
let isQuiz
if (url.origin === QUIZ_BASE_URL) {
    isQuiz = true
}
const status = url.searchParams.get('status');
const statusType = status ? status : PAGE_STATUS.ERROR;
document.head.getElementsByTagName('title')[0].innerHTML = 'SurveyHeart - ' + statusType;

statusRoot.render(<StatusPageContainer statusType={statusType} isQuiz={isQuiz}/>);
