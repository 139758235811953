import React from 'react'

const StatusData = (props) => {
    return (
        <>
            <div className="page-container">
                <div className="page-info">
                    <div className="page-info-image">
                        <img style={props.title === 'INVALIDATED' ? {width: '200px', height: '200px'} : null}
                             src={props.imgSrc}/>
                    </div>
                    <div className="page-title">
                        <p>{props.title}</p>
                    </div>
                    <div className="page-description">
                        <p>{props.desc}</p>
                        {props.desc2 ? <p>{props.desc2}</p> : null}
                        {props.desc3 ? <p>{props.desc3}</p> : null}
                    </div>
                </div>
            </div>
            {props.title === 'RESPONDED' ?
                    <div id="install-promo" className="install-promo" onClick={() => gotoPlayStore()}>
                        <img src="/images/surveyheart_new.png"/>
                        <div style={{display: 'flex', width: '65%', flexWrap: 'wrap'}}>
                            <label className="install-promo-title">SurveyHeart</label>
                            <label className="install-promo-description">Get the free App to create Survey</label>
                        </div>
                        <label className="install-promo-button" onClick={() => gotoPlayStore()}>Open</label>
                    </div>: null}
        </>
    )
}

export default StatusData

function gotoPlayStore()
{
    window.location = "/formsapppromo?call=formpage";
    analytics('send', {
        hitType: 'event',
        eventCategory: 'Form',
        eventAction: 'View',
        eventLabel: 'responded_playstore'
    });
}

