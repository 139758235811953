import React from 'react'
import StatusData from "./StatusData";
import '../css/status.css'
import {PAGE_STATUS} from "../../shared/utils/constants";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

function StatusPageContainer(props) {
    return getCurrentStatusPage(props.statusType, props.isQuiz);
}

export default StatusPageContainer

const getCurrentStatusPage = (currentPageName, isQuiz) => {
    const {t} = useTranslation()
    switch (currentPageName) {
        case PAGE_STATUS.BLOCKED:
            return <StatusData title={'BLOCKED'}
                               desc={`This` + (isQuiz ? ` quiz ` : ` form `) + `has been blocked`}
                               imgSrc={"/images/report.svg"}/>
        case PAGE_STATUS.ERROR:
            return <StatusData title={'OOPS!'}
                               desc={`We can't find `}
                               desc2={`the page that you are`}
                               desc3={` looking for :(`}
                               imgSrc={"/images/error.svg"}/>
        case PAGE_STATUS.INACTIVE:
            return <StatusData title={'CLOSED!'}
                               desc={`This` + (isQuiz ? ` quiz ` : ` form `) + `is no longer accepting ` + (isQuiz ? ` answers ` : ` responses `) }
                               imgSrc={"/images/closed.svg"}/>
        case PAGE_STATUS.PENDING:
            return <StatusData title={'INVALIDATED'}
                               desc={`This` + (isQuiz ? ` quiz ` : ` form `) + `is pending validation`}
                               imgSrc={"/images/invalidated.svg"}/>
        case PAGE_STATUS.RESPONDED:
            return <StatusData title={'RESPONDED'}
                               desc={`You've already responded to this form`}
                               imgSrc={"/images/cloud_done.svg"}/>
        case PAGE_STATUS.ANSWERED:
            return <StatusData title={t(translationKeys.answered)}
                               desc={`You've already answered this quiz`}
                               imgSrc={"/images/cloud_done.svg"}/>
        case PAGE_STATUS.HIDDEN:
            return <StatusData title={'HIDDEN'}
                               desc={`The quiz creator has hidden the results`}
                               imgSrc={"/images/hidden.svg"}/>
        case PAGE_STATUS.EXPIRED:
            return <StatusData title={`${isQuiz ? "QUIZ" : "FORM"} EXPIRED`}
                               desc={`Kindly contact the ${isQuiz ? "Quiz" : "Form"} Creator that their ${isQuiz ? "Quiz" : "Form"}`}
                               desc2={`has been expired.`}
                               imgSrc={isQuiz ? "/images/quiz_expired.svg" : "/images/form_expired.svg"}/>
        case PAGE_STATUS.LIMIT_EXCEEDED:
            return <StatusData title={'LIMIT EXCEEDED'}
                               desc={`Kindly Contact the ${isQuiz ? "Quiz" : "Form"} Builder that their Responses limit reached.`}
                               imgSrc={"/images/limit_exceeded.svg"}/>
        case PAGE_STATUS.OUT_OF_SPACE:
            return <StatusData title={'OUT OF SPACE!'}
                               desc={`Kindly inform the ${isQuiz ? 'Quiz' : 'Form'} Builder that their Storage Capacity`}
                               desc2={`for File Upload Questions has been exceeded`}
                               imgSrc={"/images/out_of_space.svg"}/>
        default:
            return <StatusData title={'OOPS!'}
                               desc={`We can't find `}
                               desc2={`the page that you are`}
                               desc3={` looking for :(`}
                               imgSrc={"/images/error.svg"}/>
    }
}
